import React, { useContext, useState } from 'react';
import qs from 'qs';
import { NarrowContent, TextContent } from '../../../theme/DefaultTheme';

import { withRouter } from "react-router-dom";
import { Table } from 'antd';
import Icon from "../../../components/icon/Icon";
import { SecondaryBlockButton } from '../../../components/button/Button';

import WizardContent from "../../../components/containers/WizardContent"
import Amount from "../../../components/display/Amount"
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOff from "../OnceOff";
import RoadBlock from '../../../components/containers/RoadBlock'
import withReservation from './ReservationProvider'


const ReservationPaymentComplete = (props) => {

    const params = props.match.params;    

    console.log("ReservationPaymentComplete",params);
    let queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    console.log("ReservationPaymentComplete.queryParams",queryParams);
    
    const [appContext] = useContext(ActionRequestContext);
    const { currentAction} = appContext;

    console.log("currentAction",currentAction)

    const handlePrintClick= () =>{
        window.print();
    }

    useState(() => {
        if ((currentAction.termsAccepted === undefined || (currentAction.verificationId === "" || currentAction.verificationId === undefined) && (currentAction.paymentReference === "" || currentAction.paymentReference === undefined))){            
            props.handleInvalidStep();
        }                     
    }, [])

    const columns = [
        {
          title: '',
          dataIndex: 'label',
          key: 'label',
        },
        {
          dataIndex: 'value',
          key: 'value',
        }
      ];
    const paymentData = [
        { key : "dev", label:"Development",value: currentAction.developmentLabel },
        { key : "unit", label:"Unit",value: currentAction.unitLabel },
        { key : "unitPrice", label:"Total Price",value:  <Amount amount={currentAction.totalPrice}/>},
        { key : "unitReservationFee", label:currentAction.reservationFee.label,value: <Amount amount={currentAction.reservationFeeAmount}/> },
        { key : "reference", label:"Reference",value: currentAction.paymentReference },
    ]

    return (
        <OnceOff>
            <RoadBlock blocked={appContext.blocked}>         
                <WizardContent>
                    <NarrowContent>
                        <TextContent style={{paddingTop:40,paddingBottom:40, textAlign:'center'}}>
                            <Icon icon="checkmark-circle" color="green" style={{ fontSize: '4em', marginBottom: '1rem' }} />

                            <h2>Congratulations!</h2>
                            <p>You have reserved <strong>Unit {currentAction.unitLabel}</strong> on <strong>{currentAction.developmentLabel}</strong></p>
                            <Table bordered={true} showHeader={false} pagination={false} dataSource={paymentData} columns={columns} style={{marginBottom:20}} />
                            <SecondaryBlockButton onClick={handlePrintClick}  style={{marginBottom:40}} >Print me</SecondaryBlockButton>

                            <h4>What now?</h4>
                            {currentAction.returnPaths.map((returnPath,index)=>{
                                    return <SecondaryBlockButton key={index} href={returnPath.url}>{returnPath.label}</SecondaryBlockButton>  
                                })}

                        </TextContent>
                    </NarrowContent>
                </WizardContent>
            </RoadBlock>
        </OnceOff>
    )
}

export default withRouter(withReservation(ReservationPaymentComplete))