import React, {useContext, useState} from 'react';
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import {EmailButton, FacebookButton, GoogleButton} from "../../components/button/Button";
import Divider from "antd/es/divider";
import Icon from "../../components/icon/Icon";
import {
    AuthContext,
    checkSignInMethods,
    handleEmailRegister,
    handleEmailSignIn,
    handleFacebookLogin,
    handleGoogleLoggedIn
} from "./AuthProvider";
import styled from "styled-components";
import {Formik} from "formik";
import { Form, Input} from 'formik-antd'
import Button from "antd/es/button";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Alert from "antd/es/alert";
import Image from "../../components/image/Image";
import {AuthBrand, RegistrationContainer, RegistrationMotivation, RegistrationOptions} from "./AuthForm";
import AuthFormTemplate from "./AuthFormTemplate";


const ConnectForm = ({actionLabel, coBrand, handleLoggedIn,customBenefits,...props})=>{

    const {t} = useTranslation();
    const {user,userData} = useContext(AuthContext);


    const handleUserAgreed = (uid) => {
        handleLoggedIn(uid)
    }





    return   <Row gutter={0}>
                <Col flex="650px" style={{textAlign:'center'}}>
                    <AuthFormTemplate coBrand={coBrand} customBenefits={customBenefits}>
                        <Alert style={{marginBottom:10}} type="info"
                               message={`You are about to connect your Property Pass account with email ${userData.email}`}/>

                        <Button size="large" type="primary" onClick={()=>handleUserAgreed(user.uid)}>Yip, I agree</Button>
                    </AuthFormTemplate>
                </Col>
            </Row>
}

export default ConnectForm