import React, { useEffect, useState } from "react";
import * as firebase from "firebase";
import {fetchPublicUser, savePublicUser} from "../../services/PropertyPassService";
import {propertyPassFb} from "../../MainRouter";
console.log("AuthProvider")

const initialState = {
    user: null,
    authenticated: false,
    setUser: () => {},
    loadingAuthState: true
}
console.log("AuthProvider",initialState)

export const AuthContext = React.createContext(initialState);

export const handleGoogleLoggedIn = () => {

    const provider = new firebase.auth.GoogleAuthProvider();

    return propertyPassFb
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
            return firebase
                .auth()
                .signInWithPopup(provider)
                .then(result => {

                    let user = result.user;
                    console.log("User data from google",user)
                    let userData = {
                        email: user.email,
                        displayName: user.displayName,
                        phoneNumber: user.phoneNumber,
                        photoUrl: user.photoURL

                    }
                    return savePublicUser(user.uid, userData)
                })

        })

}

export const handleFacebookLogin = () => {

    const provider = new firebase.auth.FacebookAuthProvider();

    return propertyPassFb
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
            return firebase
                .auth()
                .signInWithPopup(provider)
                .then(result => {

                    let user = result.user;
                    console.log("User data from facebook",user)
                    let userData = {
                        email: user.email,
                        displayName: user.displayName,
                        phoneNumber: user.phoneNumber,
                        photoUrl: user.photoURL

                    }
                    return savePublicUser(user.uid, userData)
                })

        })
}

export const handleEmailRegister = (name,email,phone,password) => {

    return propertyPassFb
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
            return firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(result => {
                    let user = result.user;
                    console.log("handleEmailRegister saving user",user)
                    let userData = {
                        email: email,
                        displayName: name,
                        phoneNumber: phone,
                        photoUrl: null
                    }
                    return savePublicUser(user.uid, userData)
                })
        })

};

export const handleEmailSignIn = (email, password) => {

    return propertyPassFb
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
            return firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(result => {
                    let user = result.user;
                    // console.log("User data from facebook",user)
                    // let userData = {
                    //     email: email,
                    //     displayName: name,
                    //     phoneNumber: user.phoneNumber,
                    //     photoUrl: null
                    // }
                    // return savePublicUser(user.uid, userData)
                })
        })

};

export const checkSignInMethods = (email) => {
    return propertyPassFb.auth().fetchSignInMethodsForEmail(email)
}

export const AuthProvider = (props) => {


    const [user,setUser] = useState(null)
    const [userData,setUserData] = useState(null)
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if(user){
                console.log("auth changed",user.uid)

                fetchPublicUser(user.uid).then(doc=>{
                    console.log('fetched')
                    setUserData(doc.data())
                })

            }
            setUser(user);
            setLoadingAuthState(false);

        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                authenticated: user !== null,
                user,
                setUser,
                userData,
                setUserData,
                loadingAuthState
            }}>
            {props.children}
        </AuthContext.Provider>
    );

}

