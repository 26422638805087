import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Welcome from "../launch/Welcome";
import EntityProvider from "../../providers/EntityProvider";
import Launch from "../launch/Launch";
export const AccountRoutes = () => {
    return (
        <Switch>
            <Route exact path="/dashboard" component={Dashboard}/>
            <Route path="/launch/:entityId/:launchId/:slug/welcome" exact={true}>
                <EntityProvider><Welcome/></EntityProvider>
            </Route>
            <Route path="/launch/:entityId/:launchId/:slug/launch" exact={true}>
                <EntityProvider><Launch/></EntityProvider>
            </Route>
        </Switch>
    );
}
