import React, {useContext, useEffect, useState} from 'react';
import Layout from "antd/es/layout";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import styled from "styled-components";
import List from "antd/es/list";
import {Space, Button, Drawer, Form} from "antd";
import Countdown from "react-countdown";
import {useViewport} from "../../hooks/ViewportProvider";
import {Helmet} from "react-helmet";
import {
    fetchLaunchInfo,
    fetchLaunchSections,
    fetchPublicUser, fetchPublicUserLaunchData,
    fetchUser,
    savePublicUserLaunchData
} from "../../services/PropertyPassService";
import {Link, useHistory, withRouter} from "react-router-dom";
import {load} from "dotenv";
import Image, {createUrlFromJson} from "../../components/image/Image";
import moment from "moment";
import Icon from "../../components/icon/Icon";
import {ThumbRounded} from "../../theme/DefaultTheme";
import Modal from "antd/es/modal";
import {handleFacebookLogin} from "../auth/Join";
import {UserContext} from "../../MainRouter";
import {AuthContext} from "../auth/AuthProvider";
import {EmailButton, FacebookButton, GoogleButton} from "../../components/button/Button";
import Divider from "antd/es/divider";
import {notification} from "antd/es";
import AuthForm from "../auth/AuthForm";
import ConnectForm from "../auth/ConnectForm";
const { Header, Content } = Layout;

const Loader = styled.div`
      text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`
const PrimaryBrand = styled.div`
    position: relative;
    padding: 60px 10% 0;
    padding-top: 60px;
    padding-right: 10%;
    padding-bottom: 0px;
    padding-left: 10%;
    margin-bottom: 2em;
`
const IntroDescription = styled.div`
    padding-top: 20%;
    margin: 0 10%;
`
const Intro = styled.div`
    position:relative;
    height:100%;
    color: white;
    font-size: 16px;
    font-family: 'Poppins', 'Tahoma', 'Geneva', sans-serif;
    font-weight: 400;
    
    h1{
        color: white;
        font-size:2em;
    }
    p{
        line-height: 30px;
        margin-top: 0;
        font-size: 1em;
        font-weight: 500;
        margin-bottom: 30px;
    }
    padding-bottom: 30px;
`
const StyledDrawer = styled(Drawer)`

 .ant-drawer-body{
   background: #FCFCFC;
   padding: 0;
 }
`
const SideCoverImage = styled.div`
    height: 100vh;   
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
`
const StyledCountdown = styled(Countdown)`
    color: white;
`
const Timer = styled.div`
    font-size:2em;
    font-weight:bold;
    &.inverted{
        color:white;
    }
`
const IntroActions = styled.div`
    position: relative;
    margin-bottom: 1em;

`
const IntroFooter = styled.div`
    position: absolute;
    bottom: 1em;
    font-size: 0.8em;
    padding: 0 10%;
`
const SecondaryBrand = styled.div`
    position: relative;
    padding: 60px 10% 0;
    padding-top: 60px;
    padding-right: 10%;
    padding-bottom: 0px;
    padding-left: 10%;
`

const MoreInfo = styled.div`
    background: #F5F5F5;
    height:100%;
    min-height: 1000px;
    padding: 10%;
    font-size: 16px;
    font-family: 'Poppins', 'Tahoma', 'Geneva', sans-serif;
    font-weight: 400;
`
const MoreInfoBlock = styled.div`
    margin-bottom: 2em;
`

export const PreLaunchTimer = ({ days,hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return "";
    } else {
        let secondsDisplay = seconds
        if(seconds<10){
            secondsDisplay = '0'+seconds
        }
        let minutesDisplay = minutes
        if(minutes<10){
            minutesDisplay = '0'+minutes
        }
        if(days>0){
            // Render a countdown
            return <Timer>{days} Days {hours}:{minutesDisplay}:{secondsDisplay}</Timer>;
        }
        else if(hours>0){
            return <Timer>{hours}:{minutesDisplay}:{secondsDisplay}</Timer>;
        }
        else if(minutes>0){
            return <Timer>{minutesDisplay}:{secondsDisplay}</Timer>;
        }
        return <Timer>{secondsDisplay}</Timer>
    }
};

export const getLinkToLaunchWelcome = (entityId,launchId,slug) =>{
    return `/launch/${entityId}/${launchId}/${slug}/welcome`
}

const PreLaunch = (props) =>{

    const params = props.match.params
    const entityId = params.entityId
    const launchId = params.launchId
    const slug = params.slug

    console.log("slug",slug)
    const {user,userData,setUserData,authenticated, loadingAuthState} = useContext(AuthContext);


    const { width, height } = useViewport();
    const breakpoint = 1000;

    const [loading, setLoading] = useState(true)
    const [registerModalState, setRegisterModalState] = useState({visible:false})
    const [userLaunchData, setUserLaunchData] = useState(null) // has the user (if logged in) subscribed to this launch
    const [launched, setLaunched] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [launchInfo, setLaunchInfo] = useState({})

    const history = useHistory();

    useEffect(()=>{
        fetchLaunchInfo(launchId).then(doc=>{


            let launchData = doc.data();
            if(launchData.entity === entityId){
                setLaunchInfo(launchData);
                console.log(launchData)
                setLoading(false)
            }else{
                alert("Incorrect entity for this launch")
            }
        })

    },[])

    useEffect(()=>{
        // if we have user data, let's check if they have access
        if(user){
            fetchPublicUserLaunchData(user.uid,launchId).then(userLaunchData=>{
                if(userLaunchData.exists){
                    setUserLaunchData(userLaunchData.data())
                }
            })
        }
    },[user])


    if(loading){
        return <Loader/>
    }



    const goToWelcomePage = () => {
        history.push(getLinkToLaunchWelcome(entityId,launchId,slug))
    }
    const handleRegisterModalOpen = () =>{
        setRegisterModalState({visible: true})
    }

    const handleRegisterModalClose = () =>{
        setRegisterModalState({visible: false})
    }

    const handleLinkUserModalOpen = () =>{
        setRegisterModalState({visible: true})
    }


    const handleLoggedIn = (uid) =>{
        console.log("handleLoggedIn",uid,launchId)
        return savePublicUserLaunchData(uid,launchId,{registered:true})
        .then(result=>{
            console.log("redirecting to welcome",result)
            goToWelcomePage()
        }).catch(err=>{
            console.log(err)
            notification['error']({
                message: "We couldn't log you in",
                description: err.message
            });
        })
    }

    const handleComplete = () => {
        setLaunched(true)
    }

    const handleOnClose = () => {
        setShowMore(false)
    }

    const handleMoreClick = () => {
        setShowMore(true)
    }


    let launchDate = moment(launchInfo.launchDate).toDate()
    console.log("userData",userData)

    // Primary action
    let primaryAction = ""

    if(user){
        if(userLaunchData){
            primaryAction = <Button onClick={goToWelcomePage} type="primary" size={"large"}  icon={<Icon icon="checkmark-circle" color={'white'}/>}>
                You've joined already!
            </Button>
        }else{
            primaryAction = <Button type="primary" size={"large"} onClick={handleLinkUserModalOpen}>Add me, {userData?userData.displayName:''}</Button>
        }
    }else{
        primaryAction = <Button type="primary" size={"large"} onClick={handleRegisterModalOpen}>Register</Button>
    }


    // Intro section is the quick basic info required for a prelaunch.
    // -----------------------
    let intro = loading ? <div>loading</div> : <Intro>
        <PrimaryBrand>
            <Image json={launchInfo.primaryBrandLogo} height={170} width={170} fit="clip"/>
        </PrimaryBrand>
        <IntroDescription>
            <h1>{launchInfo.preLaunchTitle}</h1>
            {launched ? "" :
            <StyledCountdown date={launchDate} className='inverted'
                             renderer={PreLaunchTimer} onComplete={handleComplete}/>}
            <p>{launchInfo.preLaunchInfo}
            </p>
            <IntroActions>
                {launched ? <Button type="primary" size={"large"}>Go</Button> :
                    <Space>
                        <Button ghost size={"large"} onClick={handleMoreClick}>More Information</Button>
                        {primaryAction}
                    </Space>
                }

            </IntroActions>
        </IntroDescription>

        {launchInfo.preLaunchSocialLinks ? <IntroFooter>
            <Space direction={"horizontal"}>
            {launchInfo.facebookLink ? <a href={launchInfo.facebookLink} target="_blank"><Icon icon='facebook' style={{ fontSize: '1.4em' }} color="white" /></a> :'' }
            {launchInfo.twitterLink ? <a href={launchInfo.twitterLink} target="_blank"><Icon icon='twitter' style={{ fontSize: '1.4em' }} color="white" /></a> :'' }
            {launchInfo.instagramLink ? <a href={launchInfo.instagramLink} target="_blank"><Icon icon='instagram' style={{ fontSize: '1.4em' }} color="white" /></a> :'' }
            {launchInfo.youtubeLink ? <a href={launchInfo.youtubeLink} target="_blank"><Icon icon='youtube' style={{ fontSize: '1.4em' }} color="white" /></a> :'' }
            </Space>
        </IntroFooter>:''}
    </Intro>

    // More information section is where more can be revealed
    // -------------------------------------------------------

    let moreInfo = loading ? <div>loading</div> : <MoreInfo>
        {launchInfo.moreInformationBlocks.map((moreInfoSection,index)=>{
            return <MoreInfoBlock key={index}>
                {moreInfoSection.title ? <h2>{moreInfoSection.title}</h2>:''}
                {moreInfoSection.text ? <p>{moreInfoSection.text}</p>:''}
                {moreInfoSection.images ? <Row gutter={[16, 24]}>{moreInfoSection.images.map((image,index)=>{
                    return <Col key={index} span={12}><ThumbRounded  ><Image json={image} fit='crop' width={300} height={200} /></ThumbRounded></Col>
                })}</Row>:''}
                {moreInfoSection.html ?  <div dangerouslySetInnerHTML={{ __html: moreInfoSection.html }}></div>:''}
            </MoreInfoBlock>
        })}

    </MoreInfo>

    let desktopComponent = <Layout style={{height:"100vh"}}>

        <Content>
            <div style={{background:"black", height: "100vh" }}>
                <Row>
                    <Col xs={{ span: 24}} lg={{ span: 12 }}>
                        {intro}
                    </Col>
                    <Col  xs={{ span: 24}} lg={{ span: 12 }}>
                        <SideCoverImage style={{  backgroundImage: `url('${createUrlFromJson(launchInfo.preLaunchImage,1400,1400, null)}')`}}>
                        </SideCoverImage>
                    </Col>
                </Row>
            </div>
            <StyledDrawer
                width={"50%"}
                placement="right"
                closable={true}
                onClose={handleOnClose}
                visible={showMore}
            >
                {moreInfo}
            </StyledDrawer>
        </Content>
    </Layout>

    let mobileComponent = <div style={{display:'block'}}>
        <div style={{background:"black",display:"block",height:`${height}px`,width:"100%"}}>
            <SideCoverImage style={{  backgroundImage: `url('${createUrlFromJson(launchInfo.preLaunchImage,1400,1400, null)}')`}}>
                <div  style={{background:"rgba(0,0,0,0.3)",display:"block",height:`${height}px`,width:"100%"}}>
                    {intro}
                </div>

            </SideCoverImage>
        </div>
        <div style={{background:"grey",display:"block",width:"100%", minHeight:"1000px"}}>
            {moreInfo}
        </div>
    </div>

    return <React.Fragment>
        <Helmet>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600" media="all"/>
        </Helmet>
        {width < breakpoint ? mobileComponent : desktopComponent}
        <Modal
            // title="Register now"
            mask={true}
            visible={registerModalState.visible}
            onOk={()=>{}}
            onCancel={handleRegisterModalClose}
            footer={null}
            width={600}
        >
            <div style={{padding:"0"}}>
                {user && user.email ?
                    <ConnectForm coBrand={launchInfo.primaryBrandLogo} handleLoggedIn={handleLoggedIn} />
                    :
                    <AuthForm coBrand={launchInfo.primaryBrandLogo} handleLoggedIn={handleLoggedIn} />
                }

            </div>
        </Modal>
    </React.Fragment>
}

export default withRouter(PreLaunch)