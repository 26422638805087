import React, {useContext, useState,useEffect} from 'react';
import styled from "styled-components";
import { Avatar } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { pathToCreditCheckOnceOffRoute, pathToResponderOnceOffRoute, pathToReservationOnceOffRoute } from '../../routes/publicRoutes';
import MainPageLoader from '../../components/loading/MainPageLoader';

import {createUrlFromJson} from '../../components/image/Image';
import { TextContent } from '../../theme/DefaultTheme';
import { PrimaryBlockButton}  from '../../components/button/Button';
import {ActionRequestContext} from "./ActionRequestContext";
import OnceOff from "./OnceOff.js";
import  OnceOffPageContent  from '../../components/containers/OnceOffPageContent';
import { FirstName } from '../../components/display/Name';
import { useTracked} from "../../MainRouter"

const UserAvatar  = styled(Avatar)`
    margin-bottom: ${props => props.theme.defaultBlockMargin};
`;

const OnceOffWelcome = (props) => {

    const [appContext] = useContext(ActionRequestContext);
    const [arToken] = useState(appContext.arToken);
    const [userToken] = useState(appContext.userToken);

    const [ messengerDispatch] = useTracked();
    const [startState, setStartState] = useState({});


    //propertyPassFb.analytics().logEvent('credit--welcome-page');

   
    useEffect(() =>{                    
        if (appContext.initialised === true){            
            setStartState(appContext.currentRecipient);   
            //Clear if there was a previous process       
            messengerDispatch({ type:"setGlobal", var:"currentActionInit", value:false});        
            messengerDispatch({ type:"setState", state:null});
            messengerDispatch({ type:"setStep", step:null});            
            messengerDispatch({ type:"setProgress", step:false});            
        }               
    },[appContext.initialised])
    

    let actionText = '';
    let actionButtons = []    
    if(appContext !== undefined && appContext.initialised !== undefined && appContext.actionData){
        appContext.actionData.map((action)=>{
            
            if(action.type==='creditcheck'){
                actionText = <p>You are here because {appContext.invoker.name ? <FirstName name={appContext.invoker.name}/> :''} wants you to run through the credit check process</p> 
                const nextUrl = pathToCreditCheckOnceOffRoute(appContext.arToken, appContext.userToken, "start")
                actionButtons.push({label:'Credit check yourself',url:nextUrl})                
            }else if(action.type==='responder'){                
                //If we are dealing with a list of developments OR the more-info is selected, prefetch all the information                                                    
                actionText = <div>
                                <p>Welcome and thanks for your enquiry, please help us by giving us some more information so we can best assist you</p>                                
                            </div>
                const nextUrl = pathToResponderOnceOffRoute(arToken, userToken,action.id, "welcome")
                actionButtons.push({label:"Continue",url:nextUrl});
                
                
            } else if(action.type==='reservation'){
                actionText = <div>
                                <p>
                                    You are here because {appContext.invoker.name ? <FirstName name={appContext.invoker.name}/> :''} sent you a request to reserve <strong>{action.unitLabel}</strong> on development <strong>{action.developmentLabel}</strong>
                                </p> 
                                <p>Click the button below to get started</p>
                            </div>
                const nextUrl = pathToReservationOnceOffRoute(arToken, userToken, action.id, "unit-confirm")
                actionButtons.push({label:'Continue',url:nextUrl})                                          
            }      
            
        })
    }    
    
    if(appContext !== undefined && appContext.theme !== undefined){          
        return  <OnceOff ready={appContext.initialised}>
                    <OnceOffPageContent>            
                        {(appContext.invoker !== undefined && appContext.invoker.avatar)?<UserAvatar size={125} src={createUrlFromJson(appContext.invoker.avatar,300,300)}/>:null}
                        <TextContent>
                            <h4>Hello {startState.name ? <FirstName name={startState.name}/> :''}</h4>
                            {actionText}                            
                        </TextContent>
                        {actionButtons.map((actionButton,index)=>{
                            return <PrimaryBlockButton key={index} style={{marginBottom:"1em"}}><Link to={actionButton.url}>{actionButton.label}</Link></PrimaryBlockButton> 
                        })}                                       
                    </OnceOffPageContent>  
                </OnceOff>
    }else{
        return <MainPageLoader/>
    }    
        
}

export default withRouter(OnceOffWelcome);